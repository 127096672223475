import React from 'react'
import { Wrapper, P } from '@farewill/ui'

import { CHARITY_AMOUNT_RAISED } from 'config'
import CharitiesList from 'components/CharitiesList'

const Charities = () => (
  <Wrapper centeredFromM>
    <P strong>
      Our customers have pledged {CHARITY_AMOUNT_RAISED} to charity through
      donations left in their wills
    </P>

    <CharitiesList />
  </Wrapper>
)

export default Charities
