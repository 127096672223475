import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'
import { screenMin, screen } from '@farewill/ui/helpers/responsive'

import useGetSpecialists from 'lib/specialists/useGetSpecialists'
import Specialists from 'components/Specialists'

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${screenMin.m`
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `}
`

const StyledImagesWrapper = styled(Wrapper)`
  max-width: 100%;
  margin-bottom: ${GTR.L};

  ${screenMin.m`
    margin-bottom: 0;
  `}

  ${screen.m`
    max-width: 50%;
  `}
`

const StyledLegalDetails = styled(Wrapper)`
  color: ${COLOR.BLACK};

  ${screenMin.m`
    padding-left: ${GTR.L};
  `}

  ${screenMin.l`
    margin-left: ${GTR.L};
    position: relative;
    text-align: left;

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background-color: ${COLOR.GREY.LIGHT};
    }
  `}
`

const StyledLi = styled.li`
  line-height: 1.25;
  margin: ${GTR.XXS} 0 0 0;
`

const SpecialistsWithQualifications = ({
  specialistsOutlineColor,
  group,
  title,
}) => {
  const teamMembers = useGetSpecialists(group)

  return (
    <StyledWrapper>
      <StyledImagesWrapper>
        <Specialists group={group} outlineColor={specialistsOutlineColor} />
      </StyledImagesWrapper>
      <StyledLegalDetails>
        <Wrapper>
          {title}
          <ul>
            {teamMembers.map((teamMember) => (
              <StyledLi key={teamMember.id}>
                <strong>{teamMember.name}</strong> &bull;{' '}
                {teamMember.qualifications || teamMember.title}
              </StyledLi>
            ))}
          </ul>
        </Wrapper>
      </StyledLegalDetails>
    </StyledWrapper>
  )
}

SpecialistsWithQualifications.propTypes = {
  specialistsOutlineColor: PropTypes.string,
  group: PropTypes.string.isRequired,
  title: PropTypes.string,
}

SpecialistsWithQualifications.defaultProps = {
  specialistsOutlineColor: undefined,
  title: undefined,
}

export default SpecialistsWithQualifications
