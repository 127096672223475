import React from 'react'
import { Wrapper, H, P, Grid, Image } from '@farewill/ui'

import PreventOrphan from 'components/PreventOrphan'

import { FEATURES } from './constants'

const Features = () => (
  <Wrapper container>
    <H size="L" tag="h2" decorative centered margin={['S', 0, 'XL']}>
      <PreventOrphan>Protect your family’s future with a will</PreventOrphan>
    </H>
    <Grid
      gapFromM="L"
      gapFromL="XL"
      gapFromXL="XXL"
      maxWidth={990}
      margin={[0, 'auto']}
      centered
    >
      {FEATURES.map((idea) => (
        <Grid.Item key={idea.name} span={12} spanFromM={4}>
          <Image
            path={`illustrations/${idea.illustration}`}
            width={160}
            widthFromM={200}
            stretch
          />
          <P strong margin={['S', 0]}>
            {idea.name}
          </P>
          <P>{idea.description}</P>
        </Grid.Item>
      ))}
    </Grid>
  </Wrapper>
)

export default Features
