import {
  TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE,
  CALLBACK_BOOKING_FEATURE_ENABLED,
} from 'config'

export const FEATURES = [
  {
    illustration: 'tree-with-people',
    name: 'Appoint guardians for your kids and pets',
    description:
      'Enjoy the peace of mind of knowing that your loved ones will always be in good hands.',
  },
  {
    illustration: 'cactus-turtle-house',
    name: 'Choose how you want to share out your estate',
    description:
      'Set out how much you want people to inherit, including family, friends and charities.',
  },
  {
    illustration: 'phone-laptop-questions',
    name: 'Get unlimited phone support from an specialist',
    description:
      'We talk you through every step of your will to make the whole process simple and stress-free.',
  },
]

export const STEPS = [
  ...(CALLBACK_BOOKING_FEATURE_ENABLED
    ? [
        {
          title: 'Book a 15 min callback',
          description:
            'We’ll call you to chat about your situation and set up an appointment with one of our will specialists.',
          illustration: 'cropped/blob-with-laptop',
          showCta: true,
        },
      ]
    : [
        {
          title: 'Book a callback',
          description: `Book a callback by leaving us your name and number, then we’ll get in touch within ${TELEPHONE_WILLS_CALLBACK_HOURS_GUARANTEE} hours to set up an appointment with one of our will specialists.`,
          illustration: 'cropped/blob-with-laptop',
          showCta: true,
        },
      ]),
  {
    title: 'Write your will',
    description:
      'Our will writer will take you through everything one step at a time, helping to create a will that’s tailored to you and your situation.',
    illustration: 'cropped/steps-with-blob-and-will',
  },
  {
    title: 'Make your will legally-binding',
    description:
      'We’ll draw up your will and send it out in the post, then you simply have to sign it alongside two witnesses to make it legally binding.',
    illustration: 'cropped/blob-signing-will',
  },
]
