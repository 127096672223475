import React from 'react'
import { Link } from 'gatsby'
import { Image, Button, Wrapper } from '@farewill/ui'

import PATHS from 'paths'
import {
  CALLBACK_BOOKING_FEATURE_ENABLED,
  TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL,
} from 'config'
import HeroWithImage from 'components/HeroWithImage'

import HeroPricing from './HeroPricing'

const Hero = () => (
  <HeroWithImage
    title="Write a will from the comfort of your own home"
    subtitle="Make a will over the phone and get it delivered to your door with the UK’s number one will specialists."
    additionalContent={
      <>
        <Wrapper margin={['L', 0]}>
          <HeroPricing />
        </Wrapper>
        {CALLBACK_BOOKING_FEATURE_ENABLED ? (
          <Button.Primary
            wide
            href={TELEPHONE_WILLS_APPOINTMENT_BOOKING_URL}
            data-track-event="click"
            data-track-element="cta"
            data-track-slice="hero"
          >
            Book my free callback
          </Button.Primary>
        ) : (
          <Button.Primary
            wide
            to={PATHS.WILLS.TELEPHONE_CONTACT}
            tag={Link}
            data-track-event="click"
            data-track-element="cta"
            data-track-slice="hero"
          >
            Book a callback
          </Button.Primary>
        )}
      </>
    }
    image={
      <Image
        path="illustrations/cropped/blob-with-telephone"
        width={180}
        widthFromM={400}
        stretch
      />
    }
  />
)

export default Hero
