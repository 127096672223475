import React from 'react'
import { Wrapper, H, P } from '@farewill/ui'

import SpecialistsWithQualifications from 'components/SpecialistsWithQualifications'
import { TELEPHONE_WILLS } from 'lib/products/constants'

const Legal = () => (
  <Wrapper container>
    <H size="L" decorative centeredFromM>
      The right legal expertise for your situation
    </H>
    <P centeredFromM maxWidth={550} margin={[0, 'auto', 'L']}>
      Our specialists are here to guide you through the process of making a will
      over the phone, ensuring that you have the legal support for your specific
      circumstances.
    </P>

    <SpecialistsWithQualifications group={TELEPHONE_WILLS} />
  </Wrapper>
)

export default Legal
