import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Divider, H } from '@farewill/ui'
import { COLOR, GTR } from '@farewill/ui/tokens'

import { formatPriceInPounds } from 'lib/formatting/pricing'
import {
  TELEPHONE_WILL_PRICE_IN_POUNDS,
  TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS,
  FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED,
} from 'config'

const StyledVAT = styled.span`
  color: ${COLOR.GREY.DARK};
  margin-left: ${GTR.XS};
  white-space: nowrap;
`

const PricingSection = ({ title, price }) => (
  <Grid.Item span={3} spanFromL={1} padding="XS" paddingFromM="S">
    <H context color={COLOR.BLACK} tag="h3">
      {title}
    </H>
    <H decorative size="L" tag="h4" margin={0} style={{ display: 'inline' }}>
      {formatPriceInPounds(price)}
    </H>
    <StyledVAT>inc VAT</StyledVAT>
  </Grid.Item>
)

PricingSection.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
}

const HeroPricing = () => (
  <Grid
    columns={2}
    dividerAtColumn={1}
    background={COLOR.BACKGROUND.FOG}
    bordered
    padding="XS"
    borderRadius="M"
    gap="XS"
  >
    <PricingSection
      title={`Single Will${
        FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED ? ', from' : ''
      }`}
      price={TELEPHONE_WILL_PRICE_IN_POUNDS}
    />

    <Grid.Item span={3} spanFromL={1}>
      <Divider verticalFromL />
    </Grid.Item>

    <PricingSection
      title={`Couples Will${
        FF_TELEPHONE_WILL_ADDITIONAL_COMPLEXITY_ENABLED ? ', from' : ''
      }`}
      price={TELEPHONE_WILL_COUPLES_PRICE_IN_POUNDS}
    />
  </Grid>
)

export default HeroPricing
