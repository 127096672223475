import React from 'react'
import PropTypes from 'prop-types'

const StrokedSteps = ({ children, imageWidth }) => {
  const childrenCount = children.length

  return (
    <>
      {children.map(({ props, type }, i) =>
        React.createElement(type, {
          ...props,
          key: i, // eslint-disable-line react/no-array-index-key
          index: i + 1,
          alternate: !!(i % 2),
          showSquiggle: i < childrenCount - 1,
          imageWidth,
        })
      )}
    </>
  )
}

StrokedSteps.propTypes = {
  children: PropTypes.node.isRequired,
  imageWidth: PropTypes.number,
}

StrokedSteps.defaultProps = {
  imageWidth: undefined,
}

export { default as StrokedStep } from './StrokedStep'

export default StrokedSteps
