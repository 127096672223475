import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Wrapper, Grid, Image, BackgroundImageWrapper, P } from '@farewill/ui'
import { screenMin, screenMax } from '@farewill/ui/helpers/responsive'
import { FONT, COLOR, GTR } from '@farewill/ui/tokens'

const StyledNumberWrapper = styled(BackgroundImageWrapper)`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${FONT.SIZE.L};
  font-weight: ${FONT.WEIGHT.BOLD};
  color: ${COLOR.BLACK};

  ${screenMin.m`
    width: 90px;
    height: 90px;
    font-size: ${FONT.SIZE.XL};
    font-weight: ${FONT.WEIGHT.REGULAR};
    font-family: ${FONT.FAMILY.DECORATIVE};
  `}

  ${screenMin.l`
    width: 115px;
    height: 115px;
    font-size: ${FONT.SIZE.XXL};
  `}
`

const StyledSquiggleImage = styled(Image)`
  position: absolute;
  left: 0;
  transform: translateY(-50%);
`

const StyledSquiggleWrapper = styled(Wrapper)`
  height: 0;
  position: relative;
  width: ${(2.85 / 12) * 100}%;
  margin-left: ${(1.85 / 12) * 100}%;

  ${screenMax.m`
    display: none;
  `}

  ${({ $alternate }) => $alternate && `width: ${(3.3 / 12) * 100}%;`}
`

const StyledGrid = styled(Grid)`
  align-items: flex-end;

  ${screenMin.m`
    align-items: center;
  `}
`
const StyledGridItem = styled(Grid.Item)`
  justify-self: flex-start;

  ${screenMin.m`
    justify-self: center;
  `}
`

const StyledImageInfo = styled(P)`
  display: none;

  ${screenMin.m`
    display: block;
    font-size: ${FONT.SIZE.XS};
    margin-left: ${GTR.XS}
  `}
`

const StrokedStep = ({
  children,
  background,
  index,
  alternate,
  showSquiggle,
  imagePath,
  imageWidth,
  imageInfo,
}) => (
  <Wrapper background={background}>
    <StyledGrid container gapFromM="M" gapFromL="L">
      <StyledGridItem
        span={9}
        spanFromM={4}
        startRow={1}
        startColumnFromM={alternate ? 10 : 1}
      >
        <Image
          path={imagePath}
          width={180}
          widthFromM={imageWidth}
          stretch
          margin={[0, 'auto']}
        />
        {imageInfo && <StyledImageInfo>{imageInfo}</StyledImageInfo>}
      </StyledGridItem>
      <Grid.Item
        span={3}
        spanFromL={2}
        startRow={1}
        startColumn={1}
        startColumnFromM={alternate ? 2 : 5}
      >
        <StyledNumberWrapper
          imagePath="textures/blob-1-yellow"
          imageWidth={40}
          imageWidthFromM={90}
          imageWidthFromL={115}
          cover
        >
          {index}
        </StyledNumberWrapper>
      </Grid.Item>
      <Grid.Item
        spanFromM={5}
        spanFromL={6}
        startRow={2}
        startRowFromM={1}
        startColumnFromM={alternate ? 5 : 8}
        startColumnFromL={alternate ? 4 : 7}
      >
        <Wrapper>{children}</Wrapper>
      </Grid.Item>
    </StyledGrid>
    {showSquiggle && (
      <Wrapper container containerPaddingTop={0} containerPaddingBottom={0}>
        <StyledSquiggleWrapper $alternate={alternate}>
          <StyledSquiggleImage
            path={
              alternate
                ? 'textures/squiggle-black-2'
                : 'textures/squiggle-black-1'
            }
            width={alternate ? 350 : 300}
            stretch
          />
        </StyledSquiggleWrapper>
      </Wrapper>
    )}
  </Wrapper>
)

StrokedStep.propTypes = {
  children: PropTypes.node.isRequired,
  background: PropTypes.string,
  index: PropTypes.number,
  alternate: PropTypes.bool,
  showSquiggle: PropTypes.bool,
  imagePath: PropTypes.string.isRequired,
  imageWidth: PropTypes.number,
  imageInfo: PropTypes.string,
}

StrokedStep.defaultProps = {
  index: 1,
  background: undefined,
  alternate: false,
  showSquiggle: false,
  imageWidth: 250,
  imageInfo: '',
}

export default StrokedStep
